import { createI18n } from 'vue-i18n';

const messages = {
  it: {
    views: {
      experiences: 'Esperienze',
      merch: 'Merch',
      cart: 'Carrello',
      contacts: 'Contatti',
      conditions: 'Condizioni di vendita',
      pos: 'Punti Vendita',
    },
    footer: {
      company: '© 2022 Pro Cremona',
    },
    common: {
      addtocart: 'Aggiungi al carrello',
      gotocheckout: 'Vai al checkout',
      emptysearch: 'La ricerca non ha prodotto risultati',
      cart: 'Carrello',
      editdata: 'Compila i tuoi dati',
      summary: 'Riepilogo',
      payment: 'Pagamento',
      merch: 'Merch',
      experiences: 'Esperienze',
      contacts: 'Contatti',
      contactssubtitle: 'Hai qualche dubbio su qualcosa?<br>Scrivici scegliendo l\'oggetto, ci aiuterà a risponderti più accuratamente',
      ok: 'Ok',
      shipmentdatas: 'Dati per la spedizione',
      change: 'Modifica',
      cancel: 'Annulla',
      confirm: 'Conferma',
      size: 'Taglia',
      material: 'Materiale',
      color: 'Colore',
      remove: 'Rimuovi',
      qta: 'Q.tà',
      unitprice: 'Prezzo Unitario',
      subtotal: 'Subtotale',
      continuevisiting: 'Continua a visitare il sito',
      totalcart: 'Totale Carrello',
      emptycart: 'Il tuo carrello è vuoto',
      continue: 'Continua',
      ohlee: 'ohhhh lè',
      expl1: 'Typical cremonese way to highlight the finish of a long task',
      thanks1: '💸 Grazie di aver acquistato con noi! 💸',
      seeyou: 'Ci vediamo a Cremona!',
      mailsent: 'Ti abbiamo inviato una mail con il riepilogo del tuo ordine',
      shipping_address: 'Inserisci l\'indirizzo di spedizione',
      info_needed: 'Informazioni utili per la spedizione (campanello, piano ecc.)',
      address: 'Indirizzo',
      shipping_cost: 'Spese di spedizione:',
      confirm_pay: 'Conferma e paga',
      read: 'Ho letto i',
      terms: 'Termini e le condizioni di Vendita',
      proceed_shipping: 'e voglio procedere all\'acquisto.',
      empty_cart: 'Il tuo carrello è vuoto',
      vest_che_robe: 'G’hèt vist che robe?? 😎',
      vest_che_robe2: 'Belle, eh?? Però il tuo carrello è ancora tutto<br>vuoto…non farlo piangere, poverino!',
      almost_there: 'Ci sei quasi! 🙈',
      proceed: 'Procedi con il checkout oppure metti<br>qualcos\'altro nel carrello! Hai visto quante<br>cose belle nella linea Dialetto e nella linea<br>Stampe?!',
      name: 'Nome',
      lastname: 'Cognome',
      mail: 'E-mail',
      mobile_phone: 'Mobile',
      exit: 'Esci',
      login: 'Login',
      cantlogin: 'Non riesci ad effettuare il login?',
      register: 'Registrati adesso',
      forgot_pass: 'Hai dimenticato la password?',
      click_here: 'Clicca qui',
      already_have_account: 'Hai già un account?',
      confirm_mail: 'Conferma e-mail',
      confirm_pass: 'Conferma password',
      insert_shipping_address: 'Inserisci l\'indirizzo di spedizione',
      marketing_check: 'Acconsento al trattamento dei miei dati personali per finalità di MARKETING consistenti nell\'invio, anche mediante modalità automatiche, di newsletter, comunicazioni promozionali e materiale pubblicitario.',
      marketing_check2: 'Acconsente al trattamento dei propri dati personali per finalità di rilevazione dei gusti, preferenze, abitudini e/o del grado di soddisfazione, RICERCHE DI MERCATO ed altre operazioni direttamente o indirettamente riconducibili all’attività di marketing.',
      from_euro: 'Da €',
      par_person: 'a persona',
      cart_expiring: 'Il carrello scade tra:',
      supporting: 'Acquistando i prodotti di Pro Cremona stai supportando',
      select_object: 'Scegli l\'oggetto',
      info_general: 'Info generali',
      message: 'Messaggio',
      read_privacy: 'Ho letto l\'informativa inerente la',
      read_privacy2: 'Privacy Policy e acconsento alla registrazione.',
      send: 'Invia',
      calendar: 'Calendario',
      select_time: 'Seleziona l\'orario della tua esperienza',
      select_tickets: 'Seleziona il numero di biglietti',
      tickets_not_available: 'I biglietti non sono più disponibili per la quantità scelta',
      all: 'Tutto 😎',
      gioogh: 'El Gióoch',
      neverthough: 'Hai mai pensato di comprarti tutto Corso Campi? O di costruire un hotel in Corso Mazzini?<br>Divertiti con la tradizione cremonese, i luoghi della città e i suoi modi di dire…tutto racchiuso in un<br>gioco in scatola per tutta la famiglia!',
      buy_now: 'Acquista ora!',
      dialect_line: 'La linea Dialetto fortifica il tuo spirito cremonese e ti insegna a parlare come tuo nonno.<br>La puoi regalare anche ai tuoi amici australiani grazie alla comoda traduzione inglese, check it out!',
      gotocollection: 'Vai alla collezione',
      walls: 'Muri cremonesi',
      walls2: 'Cremona è territorio di musica, di cibo, di agricoltura, di storia e di convivialità.<br>5 caratteristiche che abbiamo messo su carta e che tu puoi mettere sul muro; scopri tutta la collezione!',
      wear_cremona: 'Cremona addosso',
      wear_cremona_2: 'Nel nostro e-shop trovate 3 linee di prodotti che rendono divertente e gggiovane anche una tradizione<br>secolare. Belle idee e prodotti di qualità, tutti accomunati da tanta cura, ricerca, e ovviamente<br>cremonesità!',
      tours: 'I tour di Cremona',
      tours_desc: 'Dalla tradizione liutaria alle eccellenze gastronomiche, passando per la natura e il Po, Cremona ha<br>molto da offrire. Scopri le visite guidate e le esperienze che ti faranno toccare con mano la storia<br>millenaria della città!',
      top_exp: 'Top experience',
      exp_detail: 'Vedi esperienza',
      check_collection: 'Guarda la collezione',
      exp1_title: 'Violin Experience',
      exp1_desc: 'Hai mai sentito il profumo del legno<br>lavorato nella bottega di un liutaio?<br>Vieni a provare un’esperienza unica,<br>circondato da piallette, acciarini,<br>scalpelli e sgorbie come Stradivari nel<br>XV secolo!',
      exp2_title: 'Festa del Torrone…<br>ti racconto Cremona',
      exp2_desc: 'Cremona, città da ascoltare…e da<br>mangiare! In occasione della Festa<br>del Torrone, proponiamo un percorso<br>per scoprire la città non solo dal<br>punto di vista della sua ricchissima<br>storia millenaria, ma anche sotto<br>l’aspetto della cultura gastronomica,<br>con il torrone, i marubini, la mostarda<br>e l’immancabile salame!',
      exp3_title: 'Academia<br>Cremonensis',
      exp3_desc: 'Il prestigioso palazzo ottocentesco<br>Mina Bolzesi, sede dell’Academia<br>Cremonensis, la scuola privata di<br>Liuteria e Archetteria situata nel<br>cuore della città, ti accoglierà per<br>scoprire le tecniche di costruzione<br>del violino e dell’archetto, e assistere<br>alla comparazione musicale tra viola<br>e violino.',
      december_warning: 'Per gli ordini effettuati dopo il 18 Dicembre non è garantita la consegna entro Natale',
      size_sold_out: 'Questa taglia è sold out',
      available_only: 'Ne rimangono solo',
      free_shipping: 'Spedizione gratuita a partire da',
      size_guide: 'Guida taglia',
      shipping_time: 'Spedizione in 3 - 5 giorni lavorativi',
      pre_order: 'Pre ordina',
      could_also_like: 'Potrebbe piacerti anche',
      create_new_pass: 'Crea nuova Password',
      new_pass: 'Nuova password',
      pass_remember: 'Cliccando questo tasto di seguito prometto di ricordarmi la nuova password',
      pass_confirmation: 'Conferma cambio password',

    },
  },
  en: {
    views: {
      experiences: 'Experiences',
      merch: 'Merch',
    },
  },
};

const i18n = createI18n({
  locale: 'it',
  messages,
});

export default i18n;
