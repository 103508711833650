<template>
  <div class="container py-5 min-vh-50">
    <span class="conditions-title">DOVE TROVARE I NOSTRI PRODOTTI<br><br></span>

    <div class="store">
      <b><u>📍 <a href="https://maps.app.goo.gl/Y8LA8LnSkgxZ1w4S8" target="_blank">The Wall Art Shop</a></u></b>
      <p>
        THE WALL – Idee per vestire la parete<br>
        <strong>Luogo:</strong> via Mercatello 60, 26100 Cremona (Italy)<br>
        <strong>Tel:</strong> 0372 080649<br>
        <strong>Mail:</strong> info@thewallartshop.it<br>
      </p>
      <img src="@/assets/the_wall.jpg" alt="The Wall Art Shop" class="store-image">
      <p>
        <strong>Orario:</strong><br>
        Lunedì: Chiuso<br>
        Martedì: 10:00 - 13:00, 16:00 - 19:30<br>
        Mercoledì: 10:00 - 13:00, 16:00 - 19:30<br>
        Giovedì: 10:00 - 13:00, 16:00 - 19:30<br>
        Venerdì: 10:00 - 13:00, 16:00 - 19:30<br>
        Sabato: 10:00 - 13:00, 16:00 - 19:30<br>
        Domenica: 15:00 - 19:00<br>
      </p>
    </div>

    <div class="store">
      <b><u>📍 <a href="https://maps.app.goo.gl/9DmaQ5PVG1iNTQoR9" target="_blank">Museo Verticale</a></u></b>
      <p>
        <strong>Luogo:</strong> Piazza del Comune<br>
        <strong>Tel:</strong> 0372 495082<br>
        <strong>Mail:</strong> info@museidiocesicremona.it<br>
      </p>
      <img src="@/assets/museo_verticale.jpg" alt="Museo Verticale" class="store-image">
      <p>
        <strong>Orario:</strong><br>
        Lunedì: 10:00 - 13:00, 14:30 - 18:00<br>
        Martedì: 10:00 - 13:00, 14:30 - 18:00<br>
        Mercoledì: 10:00 - 13:00, 14:30 - 18:00<br>
        Giovedì: 10:00 - 13:00, 14:30 - 18:00<br>
        Venerdì: 10:00 - 13:00, 14:30 - 18:00<br>
        Sabato: 10:00 - 13:00, 14:30 - 18:00<br>
        Domenica: 10:00 - 13:00, 14:30 - 18:00<br>
      </p>
    </div>

    <div class="store">
      <b><u>📍 <a href="https://maps.app.goo.gl/mhTHoeBdggyWf4Bj6" target="_blank">G. Moschetti dal 1860</a></u></b>
      <p>
        <strong>Luogo:</strong> Corso Giuseppe Garibaldi, 27, Cremona, 26100, Lombardia, Italia<br>
        <strong>Telefono:</strong> 0372 20245<br>
        <strong>Mail:</strong> info@gmoschetti.it<br>
      </p>
      <img src="@/assets/moschetti.jpg" alt="G. Moschetti dal 1860" class="store-image">
      <p>
        <strong>Orario:</strong><br>
        Lunedì: Chiuso<br>
        Martedì: 09:30 - 12:30, 15:30 - 19:00<br>
        Mercoledì: 09:30 - 12:30, 15:30 - 19:00<br>
        Giovedì: 09:30 - 12:30, 15:30 - 19:00<br>
        Venerdì: 09:30 - 12:30, 15:30 - 19:00<br>
        Sabato: 09:30 - 12:30, 15:30 - 19:00<br>
        Domenica: Chiuso<br>
      </p>
    </div>

    <div class="store">
      <b><u>📍 <a href="https://maps.app.goo.gl/4FotR8dih7p1jHyU7" target="_blank">Mondadori Bookstore</a></u></b>
      <p>
        <strong>Luogo:</strong> Piazza Stradivari, 6, 26100, CREMONA (CR)<br>
        <strong>Telefono:</strong> 0372 462435<br>
        <strong>Mail:</strong> cremonamondadori@gmail.com<br>
      </p>
      <img src="@/assets/mondadori.jpg" alt="Mondadori Bookstore" class="store-image">
      <p>
        <strong>Orario:</strong><br>
        Lunedì: 9:00 - 19:30<br>
        Martedì: 9:00 - 19:30<br>
        Mercoledì: 9:00 - 19:30<br>
        Giovedì: 9:00 - 19:30<br>
        Venerdì: 9:00 - 19:30<br>
        Sabato: 9:00 - 13:00, 15:00 - 19:30<br>
        Domenica: 10:00 - 13:00, 15:00 - 19:30<br>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.conditions-title {
  font-family: Poppins;
  font-size: 30px;
  font-weight: bold;
  color: #B82620;
  margin-bottom: 15px;
}
.store {
  margin-bottom: 20px;
}
b {
  display: block;
  margin-bottom: 10px;
}
p {
  font-family: DM Sans;
  margin: 5px 0;
}
.store-image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 10px 0;
  max-height: 400px;
  object-fit: cover;
}
</style>
<script setup lang="ts">
</script>
